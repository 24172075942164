<template>
    <div>
        <v-banner class="mb-5" v-if="canIncreaseQuota.can_increase_quota">
            <v-avatar slot="icon" color="white"><v-icon color="info">info</v-icon></v-avatar>
            {{ `Your storage is filling up, consider removing unnecessary files or upgrading the storage quota to ${canIncreaseQuota.new_limit}` }}
            <template v-slot:actions>
                <v-btn small color="primary" outlined @click="increaseQuota()" :loading="loading">{{ increaseQuotaButtonLabel }}</v-btn>
            </template>
        </v-banner>
        <v-banner class="mb-5" v-if="!canIncreaseQuota.can_increase_quota && canIncreaseQuota.reason === 'rp_fs_utilization_ratio_high'">
            <v-avatar slot="icon" color="white"><v-icon color="info">info</v-icon></v-avatar>
            Your storage in this space is filling up, and so does the total combined storage of its resource pool. Upgrading of your storage quota is disabled
            until the its resource pool's global storage footprint is reduced. Please reach out to your resource pool manager for guidance.
            <template v-slot:actions>
                <contacts-menu level="rp" :rpId="defaultResourcePool.rpid" icon="mdi-account-circle-outline"></contacts-menu>
            </template>
        </v-banner>
        <v-banner class="mb-5" v-if="!canIncreaseQuota.can_increase_quota && canIncreaseQuota.reason === 'quota_max_limit_reached'">
            <v-avatar slot="icon" color="white"><v-icon color="info">info</v-icon></v-avatar>
            You storage is filling up, and the quota has already been upgraded to the maximum level. Please remove unused files or reach out to support for
            further extension.
        </v-banner>
    </div>
</template>

<script>
import { mapState } from 'vuex'
const ContactsMenu = () => import('@/modules/user/components/ContactsMenu.vue')

export default {
    components: { ContactsMenu },
    data() {
        return {
            loading: false,
            increaseQuotaButtonLabel: 'Increase quota'
        }
    },
    methods: {
        async increaseQuota() {
            this.loading = true
            try {
                const { data } = await this.$axios.get(`/snapshots/${this.$route.params.snid}/increase_quota`)
                if (data.success) {
                    this.$store.dispatch('snapshotStore/fetchSnapshotQuotaData', this.$route.params.snid)
                }
            } catch (error) {
                this.$store.dispatch('showSnackBar', {
                    snackBarText: 'Failed to increase storage quota.',
                    snackBarTimeout: 5000,
                    snackBarIcon: 'error'
                })
            }
            this.loading = false
        }
    },
    computed: {
        ...mapState('snapshotStore', ['snapshotQuota', 'canIncreaseQuota']),
        ...mapState('userStore', ['defaultResourcePool'])
    }
}
</script>
